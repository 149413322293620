import AutoModeIcon from "@mui/icons-material/AutoMode";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, FormControl, FormControlLabel, InputAdornment, MenuItem, Switch, TextField, Tooltip } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { NutritionColorIcon } from "@notemeal/shared/ui/global/Icons";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { ORGANIZATION_RECIPES, ORG_GROUP_RECIPES, STAFF_RECIPES } from "apps/web/src/pages/Auth/Org/FoodManagement/Utils";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../../componentLibrary";
import { RecipeSortDirection, useAutoShareNewNotemealRecipesQuery, useEditAutoShareNewNotemealRecipesMutation } from "../../../types";
import { useUser } from "../../../utils/tokens";
import { MenusMealTypeMultiSelect } from "../../Menus/SelectComponents/MealTypeSelect";
import { RecipeManagedByMultiSelect } from "./RecipeManagedByMultiSelect";
import { useRecipesPage } from "./useRecipesPage";

export const RecipesPageHeader = () => {
  const user = useUser();
  const {
    setCreateDialogOpen,
    resetTable,
    paginationHooks,
    tab,
    setTab,
    sortDirection,
    setSortDirection,
    createdById,
    setCreatedById,
    selectedMealTypes,
    setSelectedMealTypes,
    hasOrgGroupResourceSharing,
    selectedOwners,
    setSelectedOwners,
  } = useRecipesPage();

  const staffTab = {
    label: STAFF_RECIPES,
    icon: <NutritionColorIcon />,
  };
  const tabs = hasOrgGroupResourceSharing ? [ORGANIZATION_RECIPES, ORG_GROUP_RECIPES, staffTab] : [ORGANIZATION_RECIPES, staffTab];

  const { queryText: searchText, onChangeQueryText, onChangePage } = paginationHooks;
  const { data: autoShareData, loading: autoShareLoading, refetch } = useAutoShareNewNotemealRecipesQuery();
  const [autoShareConfirmOpen, setAutoShareConfirmOpen] = useState<boolean>(false);
  const { setMessage } = useSnackbar();
  const { isMetricLocale } = useLocaleContext();

  const handleSetTab = (tab: string) => {
    onChangeQueryText("");
    onChangePage(0);
    setSelectedMealTypes([]);
    setTab(tab);
  };

  const [editAutoShareNewNotemealRecipes] = useEditAutoShareNewNotemealRecipesMutation();

  const handleEditAutoShare = async (autoShare: boolean, shareCurrent: boolean) => {
    setAutoShareConfirmOpen(false);
    const res = await editAutoShareNewNotemealRecipes({
      variables: {
        input: {
          autoShareNewNotemealRecipes: autoShare,
          shareCurrent,
        },
      },
    });
    refetch();
    if (shareCurrent) {
      resetTable();
    }

    if (res.errors?.length) {
      setMessage(
        "error",
        `Error ${autoShare ? "enabling" : "disabling"} auto-share ${shareCurrent ? "or sharing recipes with athletes" : ""}`
      );
    } else {
      setMessage(
        "success",
        `Successfully ${autoShare ? "enabled" : "disabled"} auto-share ${shareCurrent ? "and shared recipes with athletes" : ""}`
      );
    }
  };

  const autoShareTooltip = "Auto share new Nutrition recipes with athletes";

  return (
    <Box sx={{ minWidth: 1260, display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
      <Box sx={{ display: "flex", justifyContent: !isMetricLocale ? "space-between" : "flex-end", alignItems: "flex-end" }}>
        {!isMetricLocale && <TWTabGroup tabs={tabs} onSelected={handleSetTab} />}
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
          {tab === STAFF_RECIPES && (
            <Tooltip sx={{ mx: 0, mb: 0.5 }} title={autoShareTooltip}>
              <FormControlLabel
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <AutoModeIcon />
                    Auto-Share
                  </Box>
                }
                labelPlacement="start"
                control={
                  <Switch
                    sx={{ ml: 1 }}
                    disabled={autoShareLoading}
                    checked={autoShareData?.myOrg.autoShareNewNotemealRecipes}
                    onClick={e => e.stopPropagation()}
                    onChange={async e => {
                      if (e.target.checked) {
                        setAutoShareConfirmOpen(true);
                      } else {
                        handleEditAutoShare(false, false);
                      }
                    }}
                  />
                }
              />
            </Tooltip>
          )}
          <MenusMealTypeMultiSelect
            sx={{ minWidth: 150 }}
            value={selectedMealTypes}
            onChange={setSelectedMealTypes} />
          {tab === ORGANIZATION_RECIPES && (
            <TextField
              sx={{ minWidth: 100 }}
              select
              inputProps={{
                "aria-label": "Created By",
              }}
              value={createdById === null ? "anyone" : "me"}
              onChange={e => {
                if (e.target.value === "anyone") {
                  setCreatedById(null);
                } else {
                  user && setCreatedById(user.id);
                }
              }}
              label="Created By"
            >
              <MenuItem value="anyone">Anyone</MenuItem>
              <MenuItem value="me">Me</MenuItem>
            </TextField>
          )}
          {tab === ORG_GROUP_RECIPES && (
            <FormControl>
              <RecipeManagedByMultiSelect
                searchText={searchText}
                selectedUsers={selectedOwners}
                onChangeSelectedUsers={setSelectedOwners}
                sx={{ minWidth: 200 }}
              />
            </FormControl>
          )}
          {tab === STAFF_RECIPES && (
            <TextField
              sx={{ minWidth: 150 }}
              select
              label="Date Added"
              value={sortDirection}
              onChange={e => setSortDirection(e.target.value as RecipeSortDirection)}
            >
              <MenuItem value={"ASC"}>Least Recent</MenuItem>
              <MenuItem value={"DESC"}>Most Recent</MenuItem>
            </TextField>
          )}
          <TextField
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={e => onChangeQueryText(e.target.value)}
          />
          {tab === ORGANIZATION_RECIPES && (
            <Button onClick={() => setCreateDialogOpen(true)} aria-label="Create Recipe">
              Create Recipe
            </Button>
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        title="Share All Nutrition Recipes"
        message={
          <>
            Auto-Share will automatically share new Nutrition recipes with your athletes. Would you like to also share all <b>current</b>{" "}
            Nutrition recipes with athletes?
          </>
        }
        onCancel={async () => {
          handleEditAutoShare(!autoShareData?.myOrg.autoShareNewNotemealRecipes, false);
        }}
        onConfirm={async () => {
          handleEditAutoShare(!autoShareData?.myOrg.autoShareNewNotemealRecipes, true);
        }}
        cancelLabel="Skip"
        confirmLabel="Yes"
        open={autoShareConfirmOpen}
      />
    </Box>
  );
};
